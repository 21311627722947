<template>
  <v-row class="ma-4 mt-0 pt-0">
    <v-col cols="12" class="pl-0 pr-0">
      <profile-account></profile-account>
    </v-col>
  </v-row>
</template>

<script>
import ProfileAccount from './components/ProfileAccount.vue'

export default {
  components: {
    ProfileAccount,
  },
  setup() {
    return {
      isMobileWidth:0,
    }
  },
  mounted(){
    this.isMobile_()
    window.addEventListener('resize', () => {
      this.isMobile_()
    })

    if(this.isMobileWidth === 1){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
    }
  },
  methods:{
    isMobile_() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
