<template>
  <div>
    <!-- <p class="headline black--text font-weight-bold">Profile</p> -->
    <div v-if="isMobile()" style="margin-left:-16px; margin-right:-16px">
      <v-card id="account-setting-card" elevation="0">
      <!-- tabs -->
      <v-tabs
        v-model="tab"
        show-arrows
        fixed
        :class="{ 'd-flex justify-center': isMobile() }"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="pl-8 pr-8"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span class="text-capitalize subtitle-1 font-weight-bold">{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account-settings></account-settings>
        </v-tab-item>

        <v-tab-item>
          <account-password></account-password>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    </div>
    <v-card v-else id="account-setting-card" elevation="0">
      <!-- tabs -->
      <v-tabs
        v-model="tab"
        show-arrows
        :class="{ 'd-flex justify-center': isMobile() }"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="pl-8 pr-8"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span class="text-capitalize subtitle-1 font-weight-bold">{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account-settings></account-settings>
        </v-tab-item>

        <v-tab-item>
          <account-password></account-password>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AccountSettings from './AccountSettings.vue'
import AccountPassword from './AccountPassword.vue'

export default {
  components: {
    AccountSettings,
    AccountPassword,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Account', icon: mdiAccountOutline },
      { title: 'Password', icon: mdiLockOpenOutline }
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'johnDoe',
        name: 'john Doe',
        email: 'johnDoe@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: 'The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.',
        birthday: 'February 22, 1995',
        phone: '954-006-0844',
        website: 'https://themeselection.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
  methods:{
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
