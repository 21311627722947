<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form 
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div class="px-3 pb-0">
        <v-card-text class="pt-3 pb-0">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              class="pb-0"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Current Password"
                outlined
                rounded
                dense
                :rules="[v => !!v || 'Tidak boleh kosong']"
                required
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="New Password"
                outlined
                rounded
                dense
                :rules="passwordRule"
                required
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                rounded
                dense
                :rules="passwordRule"
                required
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <!-- alert -->
      <div class="pa-3 pt-0">
        <v-col cols="12" v-if="showAlert">
          <v-alert
            :color="alertStatus"
            text
            class="mb-0"
          >
            <div class="d-flex align-start">
              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{ msg }}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
      </div>

      <!-- DESKTOP -->
      <div class="pa-3 pt-0" v-if="!isMobile()">
        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            class="text-capitalize me-3"
            rounded
            @click="validate()"
            :disabled="isLoadingSubmit"
          >
            <span v-if="isLoadingSubmit">
            <v-progress-circular
              color="grey"
              size="25"
              indeterminate
              class="mr-2"
            ></v-progress-circular>
            Loading . . .
            </span>
            <span v-else>Save</span>
          </v-btn>
        </v-card-text>
      </div>

      <!-- MOBILE -->
      <div class="pa-3 pt-0" v-else>
        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            block
            rounded
            class="text-capitalize me-3 mt-4"
            @click="validate()"
            :disabled="isLoadingSubmit"
          >
            <span v-if="isLoadingSubmit">
            <v-progress-circular
              color="grey"
              size="25"
              indeterminate
              class="mr-2"
            ></v-progress-circular>
            Loading . . .
            </span>
            <span v-else>Save</span>
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      isCPasswordVisible,
      currentPassword,
      newPassword,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      passwordRule: [ 
          v => !!v || "Tidak boleh kosong", 
          v => ( v && v.length >= 6 ) || "Minimal 6 karakter",
          v => ( v && v.length <= 8 ) || "Maksimal 8 karakter",
      ],
      msg:'loading...',
      alertStatus:'primary',
      showAlert:false,
      isLoading:false,
      isLoadingSubmit:false,
      itemData:[],
      valid:true,
    }
  },
  mounted(){
  },
  methods:{
    submit(){
      this.isLoadingSubmit = true
      let token = localStorage.getItem('token')
      console.log(token)
      axios.put(
        process.env.VUE_APP_API_ENDPOINT + 'users/changePin', {
          old_pin: this.currentPassword,
          new_pin: this.newPassword,
          confirm_new_pin : this.cPassword
        }, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          console.log(response)
          if(response.status === 200 && response.data.statusCode === 200){
            this.msg = response.data.message
            setTimeout(() => { 
              this.isLoadingSubmit = false
              this.alertStatus = 'primary'
              this.showAlert = true
              setTimeout(() => { 
                this.showAlert = false
                location.reload();
              }, 2000);
            }, 200);
          } else {
            setTimeout(() => { 
              this.isLoadingSubmit = false
              this.alertStatus = 'error'
              this.showAlert = true
              this.msg = response.data.message
              setTimeout(() => { 
                this.showAlert = false
                location.reload();
              }, 4000);
            }, 200);
          }
        }).catch(error => {
          console.log(error)
          this.resetForm()
      });
    },
    validate () {
      let checkValid = this.$refs.form.validate()
      if(checkValid){
        this.submit()
      }
    },
    resetForm(){
      this.currentPassword = this.cPassword = this.newPassword = ''
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
